<template>
  <el-footer v-if="info" class="footer-bottom">
    <div class="content">
      <div class="text">
        <div v-if="info.websiteCopyright" class="link" v-html="info.websiteCopyright" />
        <a v-if="info.websiteIcp" class="link" href="http://www.miitbeian.gov.cn/" target="_blank">{{ info.websiteIcp }}</a>
        <a v-if="info.websitePrn" class="link" :href="'http://www.beian.gov.cn/portal/registerSystemprops.info?recordcode=' + info.websitePrnNo" target="_blank">
          <img class="icon" src="~/assets/image/common_prn.png" :alt="info.websitePrn" />&nbsp;{{ info.websitePrn }}
        </a>
      </div>
      <!-- <img style="height: 100px" src="~/assets/image/gzh.png" alt="公众号" /> -->
    </div>
  </el-footer>
</template>
<script setup>
  import { indexApi } from '~/api/index.js'
  import { getStorage, setStorage } from '~/utils/storage.js'

  // 网站信息
  const info = ref({})
  onMounted(() => {
    info.value = getStorage('WebsiteInfo')
    if (!info.value) {
      indexApi.websiteInfo().then((res) => {
        setStorage('WebsiteInfo', res, 1)
        info.value = res
      })
    }
  })
</script>
<style lang="scss" scoped>
  .footer-bottom {
    z-index: 999;
    padding: 20px 0;
    height: $footer-height;
    background: #333;
    .content {
      width: 1200px;
      margin: 0 auto;
      display: flex;
      justify-content: space-between;
      .text {
        .link {
          color: #fff;
          margin-right: 20px;
          margin-bottom: 10px;
          &:hover {
            color: #fff;
            text-decoration: none;
          }
          .icon {
            width: 12px;
            height: 12px;
          }
        }
      }
    }
  }
</style>
