<template>
  <common-header />
  <el-main>
    <slot />
    <common-footer />
  </el-main>
  <!-- <common-side /> -->
</template>
<script setup>
  import { indexApi } from '~/api'

  const { data } = await useAsyncData('website', async () => {
    return indexApi.websiteInfo()
  })
  useHead({
    link: [{ rel: 'icon', type: 'image/x-icon', href: data.value?.websiteIcon }],
    meta: [
      { hid: 'keywords', name: 'keywords', content: data.value?.websiteKeyword },
      { hid: 'description', name: 'description', content: data.value?.websiteDesc }
    ]
  })
</script>
<style lang="scss" scoped>
  .el-main {
    padding: 0;
    margin: 0;
    overflow: auto;
    height: calc(100vh - $header-height);
  }
</style>
